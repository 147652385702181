import axios, { AxiosRequestConfig } from 'axios';
import { columnType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT, GET_EXCEPTION, GET_EXCEPTION_EXTRADATA_FILE } from 'const';
import { format } from 'date-fns';
import { Exception } from 'types/entities/exception';
import { EXCEPTION_LEVELS } from '../ListExceptionTemplate/ListExceptionTemplate.const';

export const getColumns: (
) => columnType<Exception>[] = () => {

  const columns: columnType<Exception>[] = [
    {
      field: 'customer',
      headerName: 'Cliente',
      flex: '0.6',
      render: ({ customer }) => (customer.code),
    },
    {
      field: 'process',
      headerName: 'Proceso',
      flex: '1',
      render: ({ process }) => (process.code),
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      flex: '0.9',
      render: ({ createdAt }) => format(new Date(createdAt), DATE_FORMAT, COMMON_OPTIONS)
    },
    {
      field: 'idTransaction',
      headerName: 'Transacción',
      flex: '1',
    },
    {
      field: 'source',
      headerName: 'Origen',
      flex: '1',
    },
    {
      field: 'ipSource',
      headerName: 'IP',
      flex: '0.7',
    },
    {
      field: 'counter',
      headerName: 'Conteo',
      flex: '0.3',
    },
    {
      field: 'level',
      headerName: 'Nivel',
      flex: '0.3',
      render: ({ level }) => {
        const rowLevel = EXCEPTION_LEVELS.find((item) => item.value === level);
        return rowLevel?.name;
      },
    },
    {
      field: 'isResolved',
      headerName: 'Estado',
      flex: '0.5',
      render: ({ isResolved }) => {
        return isResolved ? "Resuelta" : "Sin resolver";
      },
    },
  ]
  return columns;
};

export const fetchException = (
  { id }: any, config?: AxiosRequestConfig<Exception[]>
) => {
  return axios.get<Exception>(GET_EXCEPTION.replace(':id', id), config);
};

export const fetchExceptionExtraDAtaFile = (
  id: number
) => {

  return axios.get(
    GET_EXCEPTION_EXTRADATA_FILE.replace(':id', id.toString()),
    {
      responseType: "blob",
    }
  );
};

