import axios, { AxiosRequestConfig } from 'axios';
import { GET_PROJECT } from 'const';
import { Menu, Project } from 'types';

export const defaultData = {
  name: '-',
  address: '-',
  status: 'true',
  city: '-',
  state: '-',
}

export const fetchProject = ({ id }: any, config?: AxiosRequestConfig<Menu[]>) => {
  return axios.get<Project>(GET_PROJECT.replace(':id', id), config);
};