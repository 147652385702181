import { object, string, TypeOf } from 'yup';

export const CreateProjectSchema = object({
  name: string().required(),
  address: string().required(),
  status: string().required(),
  city: string().required(),
  state: string().required()
});

export type CreateProjectType = TypeOf<typeof CreateProjectSchema>;

