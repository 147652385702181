import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Grid,
  Input, MenuItem, Paper, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { useGetTable } from 'hooks';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Project } from 'types';
import { getAllDepartments, getCitiesByDepartment, getErrorsFromBackend } from 'utils';
import { UPDATE_PROJECT } from '../../../../../const';
import { states } from '../../../../../const/const';
import { defaultData, fetchProject } from './EditProjectTemplate.const';
import CreateUserTemplateLoading from './EditProjectTemplate.loading';
import { EditProjectSchema, EditProjectType } from './EditProjectTemplate.schema';

const EditProjectTemplate = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    register,
    watch,
    formState: { errors },
  } = useForm<EditProjectType>({
    resolver: yupResolver(EditProjectSchema),
    defaultValues: {
      name: '',
      address: '',
      status: 'true',
      city: '',
      state: '',
    },
  });


  const { data, loading: loadingProjects } = useGetTable<Project, { id: string }>(
    fetchProject,
    { id: id as string },
  );

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/servicios-publicos/management/project');
  };

  const { name, address, status, city, state } = watch();


  const isDisabled = () => {
    if (!name || !address || !status || !city || !state) return true;
    return false;
  };

  const onSubmit = async (projectForm: EditProjectType) => {
    try {
      setLoading(true);
      const { name, address, status, city, state } = projectForm;
      let projectStatus: boolean = status === "true" ? true : false;

      await axios.put<Project>(UPDATE_PROJECT.replace(':id', id as string),
        {
          name,
          address,
          status: projectStatus,
          city,
          state
        });
      setOpen('success');
    } catch (error) {
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<EditProjectType>(error);

      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('address', data.address);
      setValue('status', data.status ? "true" : "false");
      setValue('city', data.city);
      setValue('state', data.state);
    }
  }, [data]);

  const loadingData = loadingProjects;

  return (
    <Paper>
      {!loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                label="Nombre"
                fullWidth
                defaultValue={defaultData.name}
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Departamento"
                      fullWidth
                    >
                      {getAllDepartments().map((department) => (
                        <MenuItem
                          key={department}
                          label={`${department}`}
                          value={`${department}`}
                        >
                          {department}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => {
                  const selectedState = watch("state") || "";
                  const citiesInSelectedState = getCitiesByDepartment(selectedState);

                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Ciudad"
                      fullWidth
                    >
                      {citiesInSelectedState.map((city) => (
                        <MenuItem
                          key={city.id}
                          value={city.city}>
                          {city.city}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Dirección"
                fullWidth
                defaultValue={defaultData.address}
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address && errors.address.message}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                type="button"
                variant="text"
                color="grey"
                href="/pages/servicios-publicos/management/project"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled()}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se editó el proyecto con éxito'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
          </SnackBar>
        </form>
      ) : (
        <CreateUserTemplateLoading />
      )}
    </Paper>
  );
};

export default EditProjectTemplate;
