import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button, Grid,
  Input, MenuItem, Paper, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getAllDepartments, getCitiesByDepartment, getErrorsFromBackend } from 'utils';
import { CREATE_PROJECT } from '../../../../../const';
import { states } from '../../../../../const/const';
import { Project } from '../../../../../types';
import CreateProjectTemplateLoading from './CreateProjectTemplate.loading';
import { CreateProjectSchema, CreateProjectType } from './CreateProjectTemplate.schema';


const CreateProjectTemplate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    formState: { errors },
  } = useForm<CreateProjectType>({
    resolver: yupResolver(CreateProjectSchema),
    defaultValues: {
      name: '',
      address: '',
      status: 'true',
      city: '',
      state: '',
    },
  });

  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/servicios-publicos/management/project');
  };
  const { name, state, city } = watch();

  const isDisabled = () => {
    if (name === '' || name === '' || city == '') return true;
    return false;
  };

  const onSubmit = async (projectForm: CreateProjectType) => {
    try {
      setLoading(true);
      const { name, address, status, city, state } = projectForm;
      let projectStatus: boolean = status === "true" ? true : false;

      await axios.post<Project>(CREATE_PROJECT, {
        name,
        address,
        status: projectStatus,
        city,
        state
      });
      setOpen('success');
    } catch (error) {
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<CreateProjectType>(error);
      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    } finally {
      reset();
      setLoading(false);
    }
  };



  return (
    <Paper>
      {!loading ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                label="Nombre"
                fullWidth
                required
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Departamento"
                      fullWidth
                    >
                      {getAllDepartments().map((department) => (
                        <MenuItem
                          key={department}
                          label={`${department}`}
                          value={`${department}`}
                        >
                          {department}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => {
                  const selectedState = watch("state") || "";
                  const citiesInSelectedState = getCitiesByDepartment(selectedState);

                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Ciudad"
                      fullWidth
                    >
                      {citiesInSelectedState.map((city) => (
                        <MenuItem
                          key={city.id}
                          value={city.city}>
                          {city.city}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Dirección"
                fullWidth
                required
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address && errors.address.message}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/servicios-publicos/management/project"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled()}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se creo el proyecto con exito'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
          </SnackBar>
        </form>
      ) : (
        <CreateProjectTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateProjectTemplate;
