import { object, string, TypeOf } from 'yup';

export const EditProjectSchema = object({
  name: string().required(),
  address: string().required(),
  status: string().required(),
  city: string().required(),
  state: string().required()
});

export type EditProjectType = TypeOf<typeof EditProjectSchema>;
